"use client";
import { useCallback, useEffect, useState } from "react";
import Button from "./ui/Button";
import { GoogleIcon, Spinner } from "./icons";
import toast from "react-hot-toast";
import { useLoginSocialUserMutation } from "@/store/features/auth/authApi";
import { signIn, signOut, useSession } from "next-auth/react";
import { redirect } from "next/navigation";
import { cn } from "@/utils/class-merge";

const GoogleSignInButton = ({
	text = "Log in with Google",
	className,
}: {
	text: string;
	className?: string;
}) => {
	const [socialLogin, { isLoading, isSuccess, isError }] =
		useLoginSocialUserMutation();
	const { data: session } = useSession();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isSuccess) toast.success("Login Successful");
		if (isError) toast.error("Google Login Failed");
	}, [isSuccess, isError]);

	const Login = useCallback(
		async (response: any) => {
			setLoading(true);
			await socialLogin({
				name: response.user.name,
				avatar: response.user.image,
				email: response.user.email,
			});
			await signOut();
			redirect("/dashboard");
		},
		[socialLogin],
	);
	useEffect(() => {
		setLoading(true);
		if (session) {
			Login(session)
				.then(() => setLoading(false))
				.catch(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [session, Login]);

	return (
		<Button
			onClick={async () => {
				setLoading(true);
				await signIn("google");
				if (session) {
					await Login(session);
					setLoading(false);
				}
			}}
			disabled={loading || isLoading}
			variant="GHOST"
			className={cn("z-50 mx-auto  w-full px-3.5 py-2", className)}
		>
			<GoogleIcon className="h-[6.4vw] w-[6.4vw]" />
			<span className="flex flex-grow items-center justify-center gap-4 text-center text-[3.73vw] font-semibold">
				{text}{" "}
				{(loading || isLoading) && (
					<Spinner className={"text-secondary-700"} />
				)}
			</span>
		</Button>
	);
};

export default GoogleSignInButton;
