"use client";
import { userLoggedIn, userLoggedOut } from "@/store/actions";
import { apiSlice } from "@/store/api/apiSlice";

type RegistrationResponse = {
	message: string;
};

type RegistrationData = {};

export const authApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		register: builder.mutation<RegistrationResponse, RegistrationData>({
			query: (data) => ({
				url: "/user/register",
				method: "POST",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				body: data,
				credentials: "include" as const,
			}),
		}),
		activate: builder.mutation<any, any>({
			query: (data) => ({
				url: "/user/activate-registration",
				method: "POST",
				body: data,
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				credentials: "include",
			}),
		}),
		loginUser: builder.mutation<any, any>({
			query: (data) => ({
				url: "/auth/login",
				method: "POST",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				body: data,
				credentials: "include" as const,
			}),
			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				const loggedinUser = await queryFulfilled;
				dispatch(userLoggedIn(loggedinUser.data));
			},
		}),
		loginSocialUser: builder.mutation<any, any>({
			query: (data) => ({
				url: "/auth/oauth-signup-login",
				method: "POST",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				body: data,
				credentials: "include" as const,
			}),
			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				const loggedinUser = await queryFulfilled;
				dispatch(userLoggedIn(loggedinUser.data));
			},
		}),

		loginUserPhone: builder.mutation<any, { phone: string; otp: string }>({
			query: (data) => ({
				url: "/auth/phone-login",
				method: "POST",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				body: data,
				credentials: "include" as const,
			}),
			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				const loggedinUser = await queryFulfilled;
				dispatch(userLoggedIn(loggedinUser.data));
			},
		}),
		logoutUser: builder.mutation<any, any>({
			query: () => ({
				url: "/auth/logout",
				method: "GET",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				credentials: "include" as const,
			}),
			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				dispatch(userLoggedOut({}));
			},
		}),
	}),
});

export const {
	useRegisterMutation,
	useActivateMutation,
	useLoginUserMutation,
	useLogoutUserMutation,
	useLoginSocialUserMutation,
	useLoginUserPhoneMutation,
} = authApi;
